import React from 'react';
import SvgIcon from '@talpanetwork/talpa-publishing-core/SvgIcon';

const mobile = (props) => (
  <SvgIcon
    {...props}
    variant="HeaderSectionSvg"
    viewBox="0 0 360 50"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M23.969 0H360v50H0h1.473a10 10 0 0 0 9.874-8.42l5.71-35.686A7 7 0 0 1 23.969 0z"
    />
  </SvgIcon>
);

const tablet = (props) => (
  <SvgIcon {...props} variant="HeaderSectionSvg" viewBox="0 0 407 122">
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M41.559 10H397c5.523 0 10-4.477 10-10v122H-1 .628a10 10 0 0 0 9.844-8.242l16.32-91.395A15 15 0 0 1 41.56 10z"
    />
  </SvgIcon>
);

const pc = (props) => (
  <SvgIcon {...props} variant="HeaderSectionSvg" viewBox="0 0 590 278">
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M91.428 10H580c5.523 0 10-4.477 10-10v278L0 276h1.643a10 10 0 0 0 9.841-8.224l40.58-224.88C55.5 23.854 72.077 10 91.428 10z"
    />
  </SvgIcon>
);

mobile.propTypes = SvgIcon.propTypes;
tablet.propTypes = SvgIcon.propTypes;
pc.propTypes = SvgIcon.propTypes;

mobile.defaultProps = SvgIcon.defaultProps;
tablet.defaultProps = SvgIcon.defaultProps;
pc.defaultProps = SvgIcon.defaultProps;

const SvgHeaderSection = {
  mobile,
  tablet,
  pc
};

export default SvgHeaderSection;
