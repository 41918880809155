import React, { useEffect, ReactElement, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  onStop,
  stopPlayback,
  externalMediaEnded,
  radioplayerEventBus,
  VideoSection
} from '@imports';

import {
  onPlayDispatch,
  onPauseDispatch,
  onEndDispatch
} from '@utils/videoPlayer.utils';
import { OverviewSectionItemModel } from '@type/Overview.model';
import { ContentfulModel } from '@type/Contentful.model';
import { ImageModel } from '@type/Image.model';
import { ThemeProps } from '@theme/ThemeProps.model';

type Props = {
  content: ContentfulModel<OverviewSectionItemModel>;
  getVideosByCategory: (categoryId, limit, dist) => void;
  getLatestVideosByCategory: (categoryId, limit) => void;
  fallbackImage: ImageModel;
};

type VideoSectionPlayerProps = Props & ThemeProps;

const VideoSectionPlayer: React.FC<VideoSectionPlayerProps> = (
  props
): ReactElement | null => {
  const radioPlayerIsPlaying = useRef(false);
  const subscribed = useRef(false);

  useEffect(() => {
    if (!subscribed.current) {
      subscribed.current = true;
      radioplayerEventBus.on('rp.event.play', () => {
        radioPlayerIsPlaying.current = true;
      });
      radioplayerEventBus.on('rp.event.stop', () => {
        radioPlayerIsPlaying.current = false;
      });
    }
  }, []);

  const dispatch = useDispatch();
  let target = null;

  const onPlayEmbed = (event, type, url): void => {
    target = event.target || url;
    dispatch(stopPlayback(radioPlayerIsPlaying));
    onPlayDispatch(event, type, url, target, dispatch);
  };

  const onPauseEmbed = (event, type, url): void => {
    dispatch(externalMediaEnded());
    onPauseDispatch(event, type, url, target, dispatch);
  };

  const onEndEmbed = (event, type, url): void => {
    dispatch(externalMediaEnded());
    onEndDispatch(event, type, url, target, dispatch);
  };

  const onListItemClick = () => {
    dispatch(onStop(target));
    dispatch(externalMediaEnded());
  };

  return (
    <VideoSection
      {...props}
      onPlay={onPlayEmbed}
      onPause={onPauseEmbed}
      onEnd={onEndEmbed}
      onListItemClick={onListItemClick}
    />
  );
};

export default VideoSectionPlayer;
