import ContentfulRichText from '@components/ContentfulRichText/ContentfulRichText';
import {
  HeaderSection,
  HeaderSectionCurved,
  HeaderSectionSlider
} from '@imports';
import { ContentHeaderSectionType } from '@type/ContentHeaderSectionType';
import { LayoutId } from '@type/SingleSection.enum';
import { SvgHeaderCurve } from 'icons';
import SvgSliderHeaderCurve from 'icons/SvgSliderHeaderCurve';

import SvgHeaderSection from '../HeaderSection/SvgHeaderSection';

export const contentIdHeaderSection = ({
  section,
  theme,
  fallbackImage
}): ContentHeaderSectionType | null => {
  let { imageSizes, imageSet } = getImageConfigs(theme);
  if (section?.fields?.newHeaderSection) {
    return {
      inGrid: false,
      sectionType: (
        <HeaderSectionSlider
          svgHeaderCurve={SvgSliderHeaderCurve}
          slides={section.fields.newHeaderSection}
          renderRte={(data) => <ContentfulRichText data={data} />}
        />
      )
    };
  }
  const layout = section?.fields?.layout;
  switch (layout) {
    case LayoutId.HOMEPAGE_HEADER:
      imageSizes = {
        width: 1200,
        height: 582
      };
      return {
        inGrid: false,
        sectionType: (
          <HeaderSection
            backgroundShapes={SvgHeaderSection}
            section={section}
            imageSizes={imageSizes}
            renderRte={(data) => (
              <ContentfulRichText data={data} fallbackImage={fallbackImage} />
            )}
          />
        )
      };
    case LayoutId.BASIC_HEADER:
      return {
        inGrid: true,
        sectionType: (
          <HeaderSection
            basic
            section={section}
            backgroundShapes={SvgHeaderSection}
            renderRte={(data) => (
              <ContentfulRichText data={data} fallbackImage={fallbackImage} />
            )}
          />
        )
      };
    case LayoutId.CAMPAIGN_HEADER:
      return {
        inGrid: true,
        sectionType: (
          <HeaderSectionCurved
            content={section}
            imgOverlay={SvgHeaderCurve}
            imageSet={imageSet}
            renderRte={(data) => (
              <ContentfulRichText data={data} fallbackImage={fallbackImage} />
            )}
          />
        )
      };
    default:
      return null;
  }
};

export const getImageConfigs = (theme) => {
  let imageSizes: { width: number; height: number };
  if (theme.device === 'pc') {
    imageSizes = {
      width: 774,
      height: 465
    };
  } else if (theme.device === 'tablet') {
    imageSizes = {
      width: 770,
      height: 370
    };
  } else {
    imageSizes = {
      width: 770,
      height: 370
    };
  }
  const imageSet = [
    {
      width: 774,
      height: 465,
      screenWidth: 1024
    },
    {
      width: 770,
      height: 370,
      screenWidth: 768
    },
    {
      width: 770,
      height: 370
    }
  ];
  return { imageSizes, imageSet };
};
