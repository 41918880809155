import { onPlay, onStop, onStopAllExceptMe, tealium } from '@imports';

export const triggerTealiumForAllVideoTypes = (
  actionType: string,
  event: any,
  type: string,
  url: any,
  target: any
): void => {
  if (typeof target === 'object' && target !== null) {
    tealium.tealiumYoutubeHandler(actionType, event.target);
    return;
  }
  if (url && type !== 'youtube') {
    tealium.tealiumJWPlayereHandler(actionType, url);
    return;
  }
};

export const onPlayDispatch = (
  event: any,
  type: string,
  url: any,
  target: any,
  dispatch: any
): void => {
  dispatch(onStopAllExceptMe(target, url));
  dispatch(onPlay(target));
  triggerTealiumForAllVideoTypes('play', event, type, url, target);
};

export const onPauseDispatch = (
  event: any,
  type: string,
  url: any,
  target: any,
  dispatch: any
): void => {
  dispatch(onStop(target));
  triggerTealiumForAllVideoTypes('pause', event, type, url, target);
};

export const onEndDispatch = (
  event: any,
  type: string,
  url: any,
  target: any,
  dispatch: any
): void => {
  dispatch(onStop(target));
  triggerTealiumForAllVideoTypes('end', event, type, url, target);
};
